import React, { Component } from 'react'
import zefer from './Images/Rectangle171.png'
import heydaraliyev from './Images/Rectangle3.png'
import ataturk from './Images/Rectangle4.png'
import oneyear from './Images/Rectangle17.png'
import website from './Images/Rectangle18.png'
import war from './Images/Rectangle19.png'

export class ProjectsSection extends Component {
  render() {
    return (
      <div className='container'>
        <div className=' bg-[#fff] rounded-[8px] flex flex-col gap-[32px] p-[64px_80px] max-[600px]:p-[0px_20px]'>
          <div className='flex flex-col items-center gap-[10px]'>
              <h2 className='font-[700] text-[24px] leading-[48px] text-center text-[#3A658C] pt-[32px]'>SİZİN ÜÇÜN MARAQLI OLA BİLƏR</h2>
              <span className='font-[700] text-[13px] leading-[24px] text-center text-[#3A658C]'>
                  SON GÖRÜLMÜŞ İŞLƏRDƏN BİR NEÇƏSİ BURADA
              </span>
          </div>

          <div className='flex flex-wrap justify-center'>
              
            <img className='w-auto h-auto' src={zefer} />
            <img className='w-auto h-auto' src={heydaraliyev} />
            <img className='w-auto h-auto' src={ataturk} />
            <img className='w-auto h-auto' src={oneyear}/>
            <img className='w-auto h-auto' src={website} />
            <img className='w-auto h-auto' src={war} />

          </div>

          <div className='flex flex-col items-center gap-[32px] pb-[32px]'>
              <span className='font-[700] text-[13px] leading-[24px] text-[#3A658C]'>
                  BU SON DEYİL
              </span>

              <button className='p-[24px_32px] bg-[#FF9900] rounded-[8px] text-[#fff] font-[500] text-[16px] leading-[24px]'>
                  PORTFOLİONU GÖSTƏR
              </button>
          </div>
        </div>
      </div>
      
    )
  }
}

export default ProjectsSection