import React, { Component } from 'react'
import animation from './Images/animation.png'
import cricle from './Images/Ellipse 1.png'
import cricle1 from './Images/Ellipse 3.png'

export class Animation extends Component {
  render() {
    return (
      <div className='bg-[#fff]'>
        <div className=' max-[1135px]:hidden'>
            <div className=' absolute z-50 right-[680px] max-[1396px]:right-[655px] max-[1350px]:right-[630px] max-[1300px]:right-[600px] max-[1238px]:right-[580px] max-[1170px]:right-[545px]'>
                <img src={cricle}  />
            </div>

            <div className=' absolute z-50  right-[80px] top-[380px] max-[1396px]:right-[70px] max-[1350px]:right-[50px] max-[1300px]:right-[25px] '>
                <img src={cricle1}  />
            </div>
            
        </div>
        <div className=' relative'>
            <div className='container flex justify-between items-center max-[1134px]:flex-col max-[1134px]:pb-[30px]'>

                <div className='w-1/2 p-[72px_0px] max-[1134px]:w-full'>
                    <div className='gradient rounded-[8px] max-[1134px]:w-full'>
                        <div className='animation '>
                            <img className='w-[276px] h-[348px] max-[500px]:w-[250px] max-[500px]:h-[300px] ' src={animation} />
                        </div>
                    </div>
                </div>


                <div className='flex flex-col items-center gap-[20px]'>
                    <div className='flex justify-center'>
                        <h3 className='font-[900] text-[40px] text-center leading-[48px] text-[#4978A4]'>
                            XOŞ GÖRDÜK
                        </h3>
                    </div>

                    <div className='flex justify-center max-w-[480px]'>
                        <span className='font-[700] text-[16px] leading-[24px] text-[#3A658C] text-center'>
                            SİZİNLƏ TƏCRÜBƏMİZİ PAYLAŞMAQ ÜÇÜN
                            PORTFOLİODA OLAN İŞLƏRƏ BAXIN
                        </span>
                    </div>

                    <div className='mt-[22px]'>
                        <button className='p-[24px_32px] bg-[#FF9900] rounded-[8px] font-[500] text-[16px] leading-[24px] text-[#fff]'>
                            <span>
                                PORTFOLİONU GÖSTƏR  
                            </span>
                        </button>
                    </div>

                    
                    
                </div>
            </div>
        </div>
        
      </div>
    )
  }
}

export default Animation