import React, { Component } from 'react'
import logo from '../Images/logo.png'
import HamburgerSvg from '../Svg/HamburgerSvg'

export class Nav extends Component {
  render() {
    return (
      <div className='container max-[1134px]:bg-[#fff]'>
            <div className='flex items-center justify-between p-[24px_0px] '>
                <div className='flex items-center gap-[16px] w-full'>
                    <img className='w-[56px] h-[56px]' src={logo} />
                    <span className='font-[700] text-[24px] text-[#000] leading-[32px]'>despro.az</span>
                    <span className='font-[400] text-[12px] leading-[16px] text-[#000] w-[142px] max-[500px]:hidden'>Biznesin inkişafı üçün təsviri dəstək</span>
                </div>

                <div className='menu max-[950px]:max-w-[40px]'>
                    <input type='checkbox' id='onclick' />

                    <label for='onclick' className='lbl-resp'>
                    <HamburgerSvg  />
                    </label>

                    <ul className='flex navlist items-center gap-[32px]'>
                        <li className='font-[600] text-[16px] leading-[24px] text-[#000]'>
                            <a>
                                Biz
                            </a>
                            
                        </li>
                        <li className='font-[600] text-[16px] leading-[24px] text-[#000]'>
                            <a>
                                Portfolio
                            </a>
                        </li>
                        <li className='font-[600] text-[16px] leading-[24px] text-[#000]'>
                            <a>
                                Əlaqə
                            </a>
                        </li>
                        <li className='font-[600] text-[16px] leading-[24px] text-[#000]'>
                            <a>
                                Məqalə
                            </a>
                        </li>
                    </ul>
                </div>
                
            </div>
      </div>
    )
  }
}

export default Nav